
import React, {Component, Fragment} from "react";




class Success extends Component {

    checkpay() {
    var delay = 3000; // milliseconds
    var before = Date.now();
    const url = document.location.href;
    const data = { order: url.slice(36, url.length - 8) };
    const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;

    fetch(`${API_ADDRESS}env_url/`, {
        method: 'GET',
        headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`,
        }
    })
    .then(response => response.json())
    .then(json => {
        const finiteUrl = json.finiteUrl;

        if (window.location !== 'https://' + finiteUrl + '/#/payment') {
            fetch(`${API_ADDRESS}order_status/`, {
                method: 'POST',
                headers: {
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then(response => response.json())
            .then(json => {
                if (json['status'] === 'succeeded') {
                    while (Date.now() < before + delay) { };
                    window.open('https://' + finiteUrl + '/#', "_self");
                    var request = JSON.parse(window.sessionStorage.request);
                    fetch(`${API_ADDRESS}inf/`, {
                        method: 'POST',
                        headers: {
                            Authorization: `JWT ${localStorage.getItem('token')}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(request),
                    });
                    sessionStorage.clear();
                } else {
                    sessionStorage.clear();
                    alert('Оплата не прошла');
                    const { REACT_APP_SELF_ADDRESS, REACT_APP_API_ADDRESS, REACT_APP_ESIA_REDIRECT_LOGOUT_URL } = process.env;
                    localStorage.removeItem('token');
                    this.setState({ logged_in: false, username: '' });
                    this.setState({ displayed_form: 'login' });
                    clearInterval(this.timerID);
                    if (this.state.esia_in === 'true') {
                        this.setState({ esia_in: false });
                        localStorage.setItem('esia_login', false);
                        while (Date.now() < before + delay) { };
                        window.location.href = 'https://' + finiteUrl + '/#/';
                    };
                }
            });
        }
    })
    .catch(error => {
        console.error('Error:', error);
    });
}




    render() {



        return(

        <div className="Thanks" onLoad={this.checkpay()}>

        <div>
             <p align="left">Оплата завершена. В случае успешной оплаты вы будете перенаправлены на главную страницу. </p>
        </div>








        </div>
        )
    }


}
export default Success;