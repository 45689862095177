import React, { Component } from "react";
import DemoTwo from "./Demon";

class PdfSignedPreview extends Component {
    constructor(props) {
    super(props);
    this.OnSubmitForm = React.createRef();
    this.state = {
        pdfUrl: null,
        loading: true,
        error: null,
        no_payment: 0
        };
    }

    async componentDidMount() {
        const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
        const userRes = await fetch(`${API_ADDRESS}current_user/`, {
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`
            }
        });
        const userData = await userRes.json();
        const no_payment = userData.no_payment;
        this.setState({
            no_payment: no_payment,
        });

        this.fetchPdfSign();
    }

    fetchPdfSign = async () => {
        var request = JSON.parse(window.sessionStorage.request);
        const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
    try {
        const response = await fetch(`${API_ADDRESS}order_for_statements_signed/`, {
            method: 'POST',
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        });

        if (response.ok) {
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            this.setState({ pdfUrl: url, loading: false });
        } else {
            this.setState({ loading: false, error: 'Error while fetching PDF' });
        }
    } catch (error) {
        this.setState({ loading: false, error: 'Error occurred' });
    }
};

    async submitClick() {
    var request = JSON.parse(window.sessionStorage.request);
    const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
    const res=await fetch(`${API_ADDRESS}registryYoo/`, {
      method: 'POST',
      headers: {
        Authorization: `JWT ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
        body: JSON.stringify(request)
      });
        const json=await res.json();
        const url=await json['p_url'];
        window.open(url, "_self");
    }

    submClick() {
    const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
    fetch(`${API_ADDRESS}current_user/`, {
        method: 'GET',
        headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`,
        }
    })
    .then(response => response.json())
    .then(data => {
        if (data.no_payment === 1) {
            window.open('https://' + data.finiteUrl + '/#/successNoPayment', "_self");
        } else {
            this.submitClick();
        }
    })
    .catch(error => {
        console.error('Ошибка при получении данных о пользователе', error);
    });
}


    render() {
        const { pdfUrl, loading, error } = this.state;

        return (
            <div>
                <h2 style={{textAlign: "center"}}>
                    РАСПОРЯЖЕНИЕ НА ВЫДАЧУ ВЫПИСКИ / СПРАВКИ
                </h2>
                <div>
                    {loading && <div>Loading...</div>}
                    {error && <div>{error}</div>}
                    {pdfUrl && (
                        <div>
                            <iframe
                                title="PDF Preview"
                                src={pdfUrl}
                                width="100%"
                                height="800px"
                                style={{border: "none"}}
                            />
                        </div>
                    )}
                </div>
                <div style={styles.footer}>
                    <button className="SubmitBttn4 button2" onClick={this.submClick.bind(this)}> {this.state.no_payment ? 'Получить' : 'Оплатить'} </button>
                </div>
            </div>
        );
    }
}

const styles = {
    container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        height: "100vh",
        backgroundColor: "#f0f0f0",
        padding: "20px",
        boxSizing: "border-box",
    },
    pdfWrapper: {
        width: "80%",
        maxWidth: "800px",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        backgroundColor: "#fff",
        overflow: "hidden",
    },
    footer: {
        display: "center",
        justifyContent: "space-between",
        padding: "10px 0",
    },
};

export default PdfSignedPreview;